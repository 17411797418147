/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.ui.secondary.pointing.menu .active.item {
  border-color: #057c5a !important;
}
.documentFirstHeading::before {
  border-bottom: 3px solid #057c5a !important;
}
body {
  min-width: 400px !important;
}
.standards-homepage-image-block img {
  transform: translate(15px, -15px);
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block img {
    transform: translate(0, -7px);
  }
}
.standards-homepage-image-block a {
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block {
    margin: 3rem;
    margin-left: 1rem;
  }
}
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .ui.button,
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .accordion-title {
  color: #ffffff !important;
  background: #5a93aa !important;
  border-radius: 5px !important;
}
.inherit-link-color a {
  color: inherit;
}
.special-link-color:hover {
  background-color: #CACBCD !important;
  background-image: none !important;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px #C7D5D8 inset !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.special-link-color a {
  color: rgba(0, 0, 0, 0.6);
}
.special-link-color a:hover {
  color: rgba(0, 0, 0, 0.6);
}
.margin-auto {
  margin: auto;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.no-ul-marker,
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#news-view-1 a {
  font-size: inherit;
}
#news-view-1 div {
  margin-bottom: 1.5rem;
}
#news-view-1 span,
#news-view-1 #more-link {
  float: inline-end;
  font-size: 0.88em;
}
