/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.ui.secondary.pointing.menu .active.item {
  border-color: #057c5a !important;
}
.documentFirstHeading::before {
  border-bottom: 3px solid #057c5a !important;
}
body {
  min-width: 400px !important;
}
.standards-homepage-image-block img {
  transform: translate(15px, -15px);
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block img {
    transform: translate(0, -7px);
  }
}
.standards-homepage-image-block a {
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block {
    margin: 3rem;
    margin-left: 1rem;
  }
}
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .ui.button,
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .accordion-title {
  color: #ffffff !important;
  background: #5a93aa !important;
  border-radius: 5px !important;
}
.inherit-link-color a {
  color: inherit;
}
.special-link-color:hover {
  background-color: #CACBCD !important;
  background-image: none !important;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px #C7D5D8 inset !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.special-link-color a {
  color: rgba(0, 0, 0, 0.6);
}
.special-link-color a:hover {
  color: rgba(0, 0, 0, 0.6);
}
.margin-auto {
  margin: auto;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.no-ul-marker,
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#footer-wrapper {
  background: #242424;
  color: #ffffff;
  /* 
    a:hover {
        textDecoration: "underline";
    } */
}
#footer-wrapper #footer-mobile {
  background: grey;
}
#footer-wrapper .segment-1,
#footer-wrapper .segment-2 {
  border: none;
  padding: 20px 0px;
  border: 0px;
  margin: 0px;
}
#footer-wrapper .DropDown {
  background: blue;
  color: white;
  font-size: 25px;
}
#footer-wrapper #DropDownItems {
  margin-left: 50px !important;
}
#footer-wrapper .DropDownItems {
  margin-left: 50px !important;
}
@media screen and (max-width: 767px) {
  #footer-wrapper .list {
    flex-direction: column;
  }
}
#footer-wrapper .list .item {
  margin: 0px;
}
#footer-wrapper .footer {
  display: flex !important;
  justify-content: space-between;
  text-align: left;
}
#footer-wrapper .footer a:hover,
#footer-wrapper .footer a:focus,
#footer-wrapper .footer a:active {
  background: hsl(0deg 0% 17.12%);
  transition: all 0.2s ease-in;
  border-radius: 5px 0;
  font-weight: 500;
  outline: auto;
}
#footer-wrapper .footer .header {
  height: 5rem;
  display: table-cell;
  vertical-align: bottom;
  color: inherit;
}
@media screen and (max-width: 767px) {
  #footer-wrapper .footer .header {
    height: 0;
  }
}
#footer-wrapper .footer .content {
  margin-top: 3rem;
}
#footer-wrapper .footer .content .item {
  margin-bottom: 15px;
}
#footer-wrapper .footer .content .doeaddress {
  display: grid;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  #footer-wrapper .footer .content {
    padding: 2rem;
    margin: 0px;
  }
}
#footer-wrapper .footer.mobile .item:not(:first-child) .header {
  display: flex;
  padding: 2rem;
}
#footer-wrapper .footer.mobile .item:not(:first-child) .header::after {
  content: "\276F";
  width: 50px;
}
#footer-wrapper .segment-2 {
  background: #000;
}
#footer-wrapper .segment-2 .list {
  display: flex !important;
  justify-content: space-between;
}
#footer-wrapper .segment-2 a {
  color: white;
}
#footer-wrapper .segment-2 .list .item {
  display: flex;
  margin-bottom: 1rem;
}
#footer-wrapper .segment-2 .list .item::before {
  content: '\00B7';
  font-size: 3rem;
  margin-right: 5px;
}
