/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.ui.secondary.pointing.menu .active.item {
  border-color: #057c5a !important;
}
.documentFirstHeading::before {
  border-bottom: 3px solid #057c5a !important;
}
body {
  min-width: 400px !important;
}
.standards-homepage-image-block img {
  transform: translate(15px, -15px);
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block img {
    transform: translate(0, -7px);
  }
}
.standards-homepage-image-block a {
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block {
    margin: 3rem;
    margin-left: 1rem;
  }
}
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .ui.button,
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .accordion-title {
  color: #ffffff !important;
  background: #5a93aa !important;
  border-radius: 5px !important;
}
.inherit-link-color a {
  color: inherit;
}
.special-link-color:hover {
  background-color: #CACBCD !important;
  background-image: none !important;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px #C7D5D8 inset !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.special-link-color a {
  color: rgba(0, 0, 0, 0.6);
}
.special-link-color a:hover {
  color: rgba(0, 0, 0, 0.6);
}
.margin-auto {
  margin: auto;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.no-ul-marker,
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#tabs .menu .item {
  text-transform: capitalize;
}
#tabs .menu .item.active {
  font-weight: bolder;
  border-bottom: 5px solid;
}
#tabs .tab {
  border-left: 5px solid;
  border-left-color: #057c5a;
}
#tabs .tab.archive {
  border-left-color: hsl(349, 86%, 36%);
}
#TSPTypeStandard {
  background-color: white;
}
#TSPTypeStandard .main-content .row {
  margin-bottom: 10px;
}
#TSPTypeStandard .status {
  height: 19px;
  padding: 12px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5em;
  height: auto;
}
#TSPTypeStandard .status.archive {
  background-color: hsl(349, 86%, 36%);
}
#TSPTypeStandard .status.current {
  background-color: #057c5a;
}
#TSPTypeStandard .title {
  background-color: white;
  line-height: 125%;
  text-transform: none;
  color: #03324C;
  font-weight: 200;
  letter-spacing: normal;
  font-size: 25px;
  margin: 0;
}
#show-graph {
  color: white;
  font-weight: 400;
}
#show-graph.archive {
  background-color: hsl(349, 86%, 36%);
}
#show-graph.current {
  background-color: #057c5a;
}
.documentgraph {
  font-family: 'Rajdhani', sans-serif;
  background: white;
  cursor: all-scroll;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #000;
}
.documentgraph .nodes {
  fill: darkgray;
  cursor: pointer;
  stroke-width: 1px;
  stroke: #333;
}
.documentgraph .nodes .node rect {
  rx: 5;
  ry: 5;
}
.documentgraph .nodes .node.current-node {
  fill: #f1b859;
}
.documentgraph .nodes .label div {
  color: black;
  font-size: 100%;
  font-weight: 600;
}
.documentgraph .edgeLabels tspan {
  font-size: larger;
}
.documentgraph path {
  stroke: black;
  fill: black;
  stroke-width: 1.5px;
}
.documentgraph .svgbg {
  position: fixed;
  height: 0;
  width: 0;
}
.documentgraph .edgeLabel {
  filter: url(#svgbg);
}
#view .tags {
  display: none;
}
#document-metadata table {
  border: none;
}
#document-metadata table tr td {
  border-bottom: 0.5px solid #d0d0d0c7;
}
#document-metadata table tr td:first-child {
  background-color: #ddd;
  font-weight: 500;
}
