/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.ui.secondary.pointing.menu .active.item {
  border-color: #057c5a !important;
}
.documentFirstHeading::before {
  border-bottom: 3px solid #057c5a !important;
}
body {
  min-width: 400px !important;
}
.standards-homepage-image-block img {
  transform: translate(15px, -15px);
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block img {
    transform: translate(0, -7px);
  }
}
.standards-homepage-image-block a {
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block {
    margin: 3rem;
    margin-left: 1rem;
  }
}
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .ui.button,
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .accordion-title {
  color: #ffffff !important;
  background: #5a93aa !important;
  border-radius: 5px !important;
}
.inherit-link-color a {
  color: inherit;
}
.special-link-color:hover {
  background-color: #CACBCD !important;
  background-image: none !important;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px #C7D5D8 inset !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.special-link-color a {
  color: rgba(0, 0, 0, 0.6);
}
.special-link-color a:hover {
  color: rgba(0, 0, 0, 0.6);
}
.margin-auto {
  margin: auto;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.no-ul-marker,
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
@media screen and (max-width: 767px) {
  table.ListingTable thead tr#table-header {
    display: flex !important;
    align-items: center;
    padding: 1rem;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  table.ListingTable thead tr#table-header th {
    background: gray;
    border-radius: 1rem;
    margin-inline: 5px;
    font-size: 1.1rem;
    color: white;
  }
  table.ListingTable thead tr#table-header th button {
    color: inherit !important;
  }
  table.ListingTable thead tr#table-header th button.sorted::before {
    content: '\2719';
  }
  table.ListingTable thead tr#table-header th button:hover,
  table.ListingTable thead tr#table-header th button:active {
    background: none !important;
    text-decoration: underline;
    font-size: 1.1rem;
  }
  table.ListingTable thead tr#table-header th:not(:has(button)) {
    display: none !important;
  }
  table.ListingTable thead tr#table-header svg {
    display: none;
  }
  table.ListingTable thead tr#table-header::before {
    content: 'Sort: ';
  }
}
table.ListingTable tbody tr:nth-child(even) {
  background-color: #eceff1 !important;
}
table.ListingTable .sorted {
  font-weight: 600 !important;
  font-size: 1.05rem !important;
}
