/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.ui.secondary.pointing.menu .active.item {
  border-color: #057c5a !important;
}
.documentFirstHeading::before {
  border-bottom: 3px solid #057c5a !important;
}
body {
  min-width: 400px !important;
}
.standards-homepage-image-block img {
  transform: translate(15px, -15px);
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block img {
    transform: translate(0, -7px);
  }
}
.standards-homepage-image-block a {
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .standards-homepage-image-block {
    margin: 3rem;
    margin-left: 1rem;
  }
}
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .ui.button,
.standards-homepage-news-buttons-block .standards-homepage-rightside-buttons .accordion-title {
  color: #ffffff !important;
  background: #5a93aa !important;
  border-radius: 5px !important;
}
.inherit-link-color a {
  color: inherit;
}
.special-link-color:hover {
  background-color: #CACBCD !important;
  background-image: none !important;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px #C7D5D8 inset !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.special-link-color a {
  color: rgba(0, 0, 0, 0.6);
}
.special-link-color a:hover {
  color: rgba(0, 0, 0, 0.6);
}
.margin-auto {
  margin: auto;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.no-ul-marker,
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#standards-header {
  margin: 0px;
  padding: 0px;
}
#standards-header .header-1 {
  background-color: #057c5a;
  padding: 1rem 2rem;
}
#standards-header .header-1 .mobile-only {
  display: none;
}
#standards-header .header-1 .grid {
  margin: 0;
}
#standards-header .header-1 .grid .row {
  align-items: center;
}
#standards-header .header-1 a {
  color: white;
}
#standards-header .header-2 {
  display: flex;
  width: 100%;
  padding: 0.5rem 3rem;
  align-items: center;
  justify-content: space-between;
}
#standards-header .header-2 #navigation .menu {
  margin: 0;
  flex-wrap: wrap;
}
#standards-header .header-2 #navigation .item {
  padding: 0.8rem 0;
  margin: 0.5rem 1rem;
}
@media screen and (max-width: 992px) {
  #standards-header .header-2 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  #standards-header .header-1 {
    height: 11rem;
    padding: 0;
  }
  #standards-header .header-1 .row {
    justify-content: space-around;
  }
  #standards-header .header-1 .row > .div {
    display: flex;
  }
  #standards-header .header-1 .block-menu {
    order: 1;
    padding-inline: 0;
    display: flex;
  }
  #standards-header .header-1 .block-menu button {
    padding-inline: 0;
  }
  #standards-header .header-1 .block-logo {
    padding: 21px 11px;
    order: 2;
    transform: none;
    display: flex;
    justify-content: center;
  }
  #standards-header .header-1 .block-logo img {
    width: 210px;
  }
  #standards-header .header-1 .block-logo .text {
    display: none;
  }
  #standards-header .header-1 .mobile-only {
    order: 3;
    display: flex;
    align-items: center;
  }
  #standards-header .header-1 .block-search {
    order: 4;
    flex-basis: 100%;
    padding: 0;
  }
  #standards-header .header-1 .ui.image {
    max-width: unset;
  }
}
#header-side-menu {
  width: clamp(10rem, calc(20rem + 5vw), 1000px);
}
@media screen and (max-width: 767px) {
  #header-side-menu {
    height: 100%;
    width: 100%;
  }
}
.actions .segment {
  all: unset;
}
#header-side-menu .only-mobile-links {
  display: none;
}
@media screen and (max-width: 992px) {
  #header-side-menu .only-mobile-links {
    display: block;
  }
}
