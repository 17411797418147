.CookieConsentContainer {
  border-left: 10px solid #057c5a;
  border-radius: 5PX;
  padding: 6px;
}
.CookieConsent button {
  background: #057c5a;
  box-shadow: none;
  cursor: pointer;
  padding: 1rem 1.5rem;
  margin: 1rem;
  color: white;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 5px;
}
.CookieConsent button:hover,
.CookieConsent button:active,
.CookieConsent button:focus {
  background-color: #08a277;
}
